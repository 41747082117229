import {Tab, Tabs} from '@blueprintjs/core';
import * as React from 'react';

import {getResponseData, getResponseError} from '../../ice-client-react';
import {useClub} from '../../store/clubs';
import {getClasses} from '../../utils/css';

import {ClubGames} from './ClubGames';
import {TournamentDetailed} from './TournamentDetailed';

const classes = getClasses({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export const Statistics: React.FC<{clubOrDisplayId: string}> = ({
  clubOrDisplayId,
}) => {
  const response = useClub(clubOrDisplayId);
  const club = getResponseData(response);
  const error = getResponseError(response);

  if (error != null) {
    return <div>Error</div>;
  }
  if (club == null) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Tabs id="StatsTabs">
        <Tab id="Games" title="Games" panel={<ClubGames club={club} />} />
        <Tab
          id="Tournament details"
          title="Tournament details"
          panel={<TournamentDetailed club={club} />}
        />
      </Tabs>
    </div>
  );
};
