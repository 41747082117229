import {Button, Divider, HTMLTable, InputGroup} from '@blueprintjs/core';
import moment from 'moment';
import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';

import {useClubsProxy} from 'src/store/clubs';
import {useRequest} from 'src/utils/useRequest';

import {getClasses} from '../../utils/css';
import {
  parseString,
  skipValidation,
  useInputState,
} from '../../utils/inputState';
import {centsToMainUnits, formatRakeSize} from '../../utils/numbers';
import {ResponseErrorToast} from '../../utils/toast';
import {useCancelContext} from '../../utils/useCancelContext';

const classes = getClasses({
  buttons: {
    display: 'flex',
    justifyContent: 'right',
    flexDirection: 'column',
  },
  getStats: {
    display: 'flex',
    $nest: {
      '& > *': {
        margin: '30px',
      },
    },
  },
  table: {
    marginTop: '2%',
  },
});

export const TournamentDetailed: React.FunctionComponent<{
  club: Gazebo.Clubs.Club;
}> = ({club}) => {
  const [ctx] = useCancelContext();

  const {getClubTournamentStats} = useClubsProxy();

  const [onTournamentIdChanged, tournamentId] = useInputState(
    '',
    parseString,
    skipValidation,
  );

  const [getClubTournamentStatsResponse, doGetClubTournamentStats] = useRequest(
    async () => {
      if (!tournamentId.rawValue.startsWith('tournament-')) {
        throw new Error('Incorrect tournamentID');
      }
      return await getClubTournamentStats(club.clubId, tournamentId.rawValue);
    },
    [getClubTournamentStats, club, tournamentId.rawValue],
    ctx,
  );

  return (
    <div className={classes.buttons}>
      <InputGroup
        value={tournamentId.rawValue}
        id="text-input"
        onChange={onTournamentIdChanged}
        placeholder="Tournament ID"
      />
      <div className={classes.getStats}>
        <Button
          text={'Get tournament stats'}
          onClick={doGetClubTournamentStats}
          disabled={!tournamentId.rawValue}
          loading={getClubTournamentStatsResponse?.type === 'started'}
        />
      </div>
      <TournamentStats stats={getClubTournamentStatsResponse?.data} />
      <ResponseErrorToast response={getClubTournamentStatsResponse} />
    </div>
  );
};

const TournamentStats: React.FunctionComponent<{
  stats?: Gazebo.Clubs.TournamentDetailedStats;
}> = ({stats}) => {
  if (!stats) {
    return null;
  }

  const players = stats.players ?? [];

  return (
    <>
      <div>ID: {stats.tournamentId}</div>
      <div>Name: {stats.tournamentName}</div>
      <div>Buyin: {centsToMainUnits(stats.buyIn.toNumber())}</div>
      <div>Fee: {formatRakeSize(stats.rakeSize)}</div>
      <div>Start stack: {centsToMainUnits(stats.startStack.toNumber())}</div>
      <div>Game type: {stats.gameType}</div>
      <div>Places paid: {stats.placesPaid}</div>
      <div>
        Started: {moment(stats.startAtMs.toNumber()).format('DD.MM.YYYY HH:mm')}
      </div>
      <div>
        Duration in ms:{' '}
        {moment.duration(stats.durationMs?.toNumber()).humanize()}
      </div>
      <div>Total buyin: {centsToMainUnits(stats.totalBuyIn?.toNumber())}</div>
      <div>Total rake: {centsToMainUnits(stats.totalRake?.toNumber())}</div>
      <div>Winner: {stats.winnerPlayerId}</div>
      <Divider />
      <HTMLTable striped={true} condensed={true} className={classes.table}>
        <thead>
          <tr>
            <th>Player id</th>
            <th>Club id</th>
            <th>Place</th>
            <th>Prize</th>
            <th>Profit</th>
            <th>Hands played</th>
          </tr>
        </thead>
        <tbody>
          {players.map((player) => {
            return (
              <tr key={player.playerId}>
                <td>{player.playerId}</td>
                <td>{player.clubId}</td>
                <td>{player.place}</td>
                <td>{centsToMainUnits(player.prize?.toNumber())}</td>
                <td>{centsToMainUnits(player.profit?.toNumber())}</td>
                <td>{player.handsPlayed?.toNumber()}</td>
              </tr>
            );
          })}
        </tbody>
      </HTMLTable>
    </>
  );
};
