import {Button, HTMLTable} from '@blueprintjs/core';
import {DateRange, DateRangeInput, TimePrecision} from '@blueprintjs/datetime';
import {Ice} from 'ice';
import moment from 'moment';
import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';

import {useClubsProxy} from 'src/store/clubs';
import {useRequest} from 'src/utils/useRequest';

import {create} from '../../utils/create';
import {getClasses} from '../../utils/css';
import {centsToMainUnits, formatRakeSize} from '../../utils/numbers';
import {ResponseErrorToast} from '../../utils/toast';
import {useCancelContext} from '../../utils/useCancelContext';

const classes = getClasses({
  buttons: {
    display: 'flex',
    justifyContent: 'right',
    flexDirection: 'column',
  },
  getReport: {
    display: 'flex',
    $nest: {
      '& > *': {
        margin: '30px',
      },
    },
  },
  date: {
    display: 'flex',
    marginTop: '250px',
    $nest: {
      '& > *': {
        margin: '5px',
      },
    },
  },
  table: {
    marginTop: '2%',
  },
});

export const ClubGames: React.FunctionComponent<{club: Gazebo.Clubs.Club}> = ({
  club,
}) => {
  const [ctx] = useCancelContext();

  const {getClubGamesStats} = useClubsProxy();

  const [dates, setDates] = React.useState<DateRange>([null, null]);
  const handleDatesChange = React.useCallback(
    (newDates: DateRange) => {
      setDates(newDates);
    },
    [setDates],
  );

  const [getClubGamesStatsResponse, doGetClubGamesStats] = useRequest(
    async () => {
      const start = dates[0];
      const finish = dates[1];
      if (start == null || finish == null) {
        throw new Error('Incorrect dates');
      }
      return await getClubGamesStats(
        club.clubId,
        create(Gazebo.Clubs.AbstractStatsFilter, {
          timespan: create(Gazebo.Clubs.FixedTimeSpan, {
            fromMs: new Ice.Long(start.getTime()),
            toMs: new Ice.Long(finish.getTime()),
          }),
        }),
      );
    },
    [getClubGamesStats, club, dates],
    ctx,
  );

  return (
    <div className={classes.buttons}>
      <div className={classes.date}>
        <DateRangeInput
          formatDate={(date) => moment(date).format('YYYY-MM-DD HH:mm:ss')}
          parseDate={(str) => moment(str, 'YYYY-MM-DD HH:mm:ss').toDate()}
          closeOnSelection={false}
          onChange={handleDatesChange}
          placeholder={'YYYY-MM-DD HH:mm (moment)'}
          timePickerProps={{
            precision: TimePrecision.MINUTE,
            showArrowButtons: true,
          }}
        />
      </div>
      <div className={classes.getReport}>
        <Button
          text={'Get stats'}
          onClick={doGetClubGamesStats}
          disabled={dates[0] == null || dates[1] == null}
          loading={getClubGamesStatsResponse?.type === 'started'}
        />
      </div>
      <ClubGamesRows stats={getClubGamesStatsResponse?.data} />
      <ResponseErrorToast response={getClubGamesStatsResponse} />
    </div>
  );
};

const ClubGamesRows: React.FunctionComponent<{
  stats?: Gazebo.Clubs.GamesStats;
}> = ({stats}) => {
  if (!stats) {
    return null;
  }

  const rows = (
    (stats.cashTablesStats ?? []) as Gazebo.Clubs.AbstractGameStatsSeq
  ).concat(stats.tournamentsStats ?? []);

  return (
    <>
      <HTMLTable striped={true} condensed={true} className={classes.table}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Start</th>
            <th>Type</th>
            <th>Fee</th>
            <th>Winner</th>
            <th>Total Rake</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, ind) => {
            if (row instanceof Gazebo.Clubs.ClubTableStats) {
              return (
                <tr key={ind}>
                  <td>{row.tableParams.tableId}</td>
                  <td>{row.tableParams.tableName}</td>
                  <td>
                    {moment(row.tableParams.tableStartMs.toNumber()).format(
                      'DD.MM.YYYY HH:mm',
                    )}
                  </td>
                  <td>{row.tableParams.gameType}</td>
                  <td>
                    {formatRakeSize(row.tableParams.tableRake.toNumber())}
                  </td>
                  <td>{row.winnerPlayerId}</td>
                  <td>{centsToMainUnits(row.totalRake?.toNumber())}</td>
                </tr>
              );
            }
            if (row instanceof Gazebo.Clubs.TournamentStats) {
              return (
                <tr key={ind}>
                  <td>{row.tournamentId}</td>
                  <td>{row.tournamentName}</td>
                  <td>
                    {moment(row.startAtMs.toNumber()).format(
                      'DD.MM.YYYY HH:mm',
                    )}
                  </td>
                  <td>{row.gameType}</td>
                  <td>{formatRakeSize(row.rakeSize)}</td>
                  <td>{row.winnerPlayerId}</td>
                  <td>{centsToMainUnits(row.totalRake?.toNumber())}</td>
                </tr>
              );
            }
            return (
              <tr key={ind}>
                <td>empty</td>
              </tr>
            );
          })}
        </tbody>
      </HTMLTable>
    </>
  );
};
