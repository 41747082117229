import {
  Button,
  Checkbox,
  Classes,
  HTMLTable,
  InputGroup,
  Tab,
  Tabs,
} from '@blueprintjs/core';
import moment from 'moment';
import * as React from 'react';

import {Gazebo} from '@slices/Gazebo';
import {Subscriptions} from '@slices/Subscriptions';

import {ResponseErrorToast} from 'src/utils/toast';

import {useAuthContext} from '../auth';
import {
  getResponseData,
  getResponseError,
  useIceProxy,
  useSubscription,
} from '../ice-client-react';
import {useClub, useClubsProxy} from '../store/clubs';
import {useUserInfoPrx} from '../store/userInfo';
import {create} from '../utils/create';
import {getClasses} from '../utils/css';
import {nullableToList} from '../utils/emptyList';
import {useIdempotenceKey} from '../utils/idempotencyKey';
import {parseString, skipValidation, useInputState} from '../utils/inputState';
import {centsToMainUnits} from '../utils/numbers';
import {Stopper} from '../utils/Stopper';
import {useCancelContext} from '../utils/useCancelContext';
import {useRequest} from '../utils/useRequest';

const classes = getClasses({
  root: {
    marginTop: '10%',
  },
});

enum StatsTypes {
  NLHE = 'NLHE',
  PLO4 = 'PLO4',
  PLO5 = 'PLO5',
  PLO6 = 'PLO6',
  Tournaments = 'Tournaments',
  Cash = 'Cash',
  All = 'All',
  undefined = 'undefined',
}

const statsTypeToIce = (statsType: string) => {
  switch (statsType) {
    case StatsTypes.NLHE:
      return create(Gazebo.Clubs.NLHEStatsType, {});
    case StatsTypes.PLO4:
      return create(Gazebo.Clubs.PLO4StatsType, {});
    case StatsTypes.PLO5:
      return create(Gazebo.Clubs.PLO5StatsType, {});
    case StatsTypes.PLO6:
      return create(Gazebo.Clubs.PLO6StatsType, {});
    case StatsTypes.Tournaments:
      return create(Gazebo.Clubs.TournamentStatsType, {});
    case StatsTypes.Cash:
      return create(Gazebo.Clubs.CashGameStatsType, {});
    case StatsTypes.All:
      return create(Gazebo.Clubs.AllGamesStatsType, {});
    case StatsTypes.undefined:
      return undefined;
  }
  throw new Error(`Unknown stats type: ${statsType}`);
};

export const PlayersManagement: React.SFC<{clubOrDisplayId: string}> = ({
  clubOrDisplayId,
}) => {
  const [state, setState] = React.useState<React.ReactText>();
  const [playersTabState, setPlayersTabState] =
    React.useState<React.ReactText>();
  const response = useClub(clubOrDisplayId);
  const club = getResponseData(response);
  const error = getResponseError(response);
  const clubUserIds = nullableToList(club?.userIds);
  const agentUserIds = club?.agentUserIds ?? [];
  const managers: Gazebo.Clubs.Managers = club?.managers ?? new Map();
  const {leagueManagers} = club || {};
  const waitingApprovalUserIds = nullableToList(club?.userWaitingApprovalIds);
  const userInfoPrx = useUserInfoPrx();
  const clubsPrx = useClubsProxy();

  const userAgents = club?.userAgents ?? new Map();

  const subscribeToUsers = React.useCallback(
    (subscriber: Subscriptions.CollectionSubscriberPrx<Gazebo.UserInfo.User>) =>
      userInfoPrx.subscribeToUsers(
        subscriber,
        clubUserIds.concat(waitingApprovalUserIds),
      ),
    [userInfoPrx, clubUserIds, waitingApprovalUserIds],
  );
  const usersSubscriptionState = useSubscription(subscribeToUsers);
  const displayNames = new Map(
    getResponseData(usersSubscriptionState)
      ?.filter((user) => clubUserIds.includes(user.userId))
      .map((user) => [user.userId, user.displayName]),
  );
  const displayIds = React.useMemo(
    () =>
      new Map(
        getResponseData(usersSubscriptionState)
          ?.filter((user) => clubUserIds.includes(user.userId))
          .map((user) => [user.userId, user.displayId]),
      ),
    [usersSubscriptionState, clubUserIds],
  );
  const waitingApprovalDisplayNames = new Map(
    getResponseData(usersSubscriptionState)
      ?.filter((user) => waitingApprovalUserIds.includes(user.userId))
      .map((user) => [user.userId, user.displayName]),
  );
  const waitingApprovalDisplayIds = React.useMemo(
    () =>
      new Map(
        getResponseData(usersSubscriptionState)
          ?.filter((user) => waitingApprovalUserIds.includes(user.userId))
          .map((user) => [user.userId, user.displayId]),
      ),
    [usersSubscriptionState, waitingApprovalUserIds],
  );

  const getClubPlayerStats = React.useMemo(
    () =>
      club
        ? () =>
            clubsPrx.getClubMemberStats(
              club.clubId,
              club.userIds,
              create(Gazebo.Clubs.ClubMemberStatsFilter, {
                gameType: statsTypeToIce(
                  playersTabState?.toString() ?? 'unknown',
                ),
              }),
            )
        : () => null,
    [clubsPrx, club, playersTabState],
  );
  const [ctx] = useCancelContext();
  const [playerStatsResponse, getPlayerStats] = useRequest(
    getClubPlayerStats,
    [getClubPlayerStats],
    ctx,
  );

  const playerStats = new Map(
    getResponseData(playerStatsResponse)?.map((player) => [
      player.playerId,
      player,
    ]) ?? [],
  );

  if (error != null) {
    return <div>Error</div>;
  }
  if (club == null) {
    return null;
  }
  const ClubMembersPanel = (
    <ClubMembers
      club={club}
      userIds={clubUserIds}
      playerStats={playerStats}
      displayNames={displayNames}
      displayIds={displayIds}
      agentUserIds={agentUserIds}
      userAgents={userAgents}
      managers={managers}
      leagueManagers={leagueManagers}
    />
  );
  return (
    <div className={classes.root}>
      <Tabs
        id="TabsExample"
        onChange={(id) => {
          getPlayerStats();
          setState(id);
        }}
        selectedTabId={state}
      >
        <Tab
          id="pending"
          title="Pending requests"
          panel={
            <PendingRequests
              clubId={club.clubId}
              userIds={waitingApprovalUserIds}
              displayNames={waitingApprovalDisplayNames}
              displayIds={waitingApprovalDisplayIds}
            />
          }
        />
        <Tab
          id="players"
          title="Players"
          panel={
            <Tabs
              id="PlayersTabs"
              onChange={(id) => {
                setPlayersTabState(id);
                getPlayerStats();
              }}
              selectedTabId={playersTabState}
            >
              <Tab id={StatsTypes.All} title="All" panel={ClubMembersPanel} />
              <Tab id={StatsTypes.NLHE} title="NLHE" panel={ClubMembersPanel} />
              <Tab id={StatsTypes.PLO4} title="PLO4" panel={ClubMembersPanel} />
              <Tab id={StatsTypes.PLO5} title="PLO5" panel={ClubMembersPanel} />
              <Tab id={StatsTypes.PLO6} title="PLO6" panel={ClubMembersPanel} />
              <Tab id={StatsTypes.Cash} title="Cash" panel={ClubMembersPanel} />
              <Tab
                id={StatsTypes.Tournaments}
                title="Tournaments"
                panel={ClubMembersPanel}
              />
              <Tab
                id={StatsTypes.undefined}
                title="undefined"
                panel={ClubMembersPanel}
              />
            </Tabs>
          }
        />
      </Tabs>
    </div>
  );
};

const ClubMembers: React.FC<{
  club: Gazebo.Clubs.Club;
  userIds: string[];
  playerStats: Map<string, Gazebo.Clubs.ClubMemberStats>;
  displayNames: Map<string, string>;
  displayIds: Map<string, string>;
  agentUserIds: string[];
  userAgents: Map<string, string>;
  managers: Gazebo.Clubs.Managers;
  leagueManagers?: Gazebo.Clubs.LeagueManagers;
}> = ({
  club,
  userIds,
  playerStats,
  displayNames,
  displayIds,
  agentUserIds,
  userAgents,
  managers,
  leagueManagers,
}) => {
  if (userIds.length === 0) {
    return <div>No players in club</div>;
  }
  return (
    <HTMLTable striped={true}>
      <thead>
        <tr>
          <th>Nickname</th>
          <th>User ID</th>
          <th>Agent ID</th>
          <th>Hands Played</th>
          <th>Last Played Session Date</th>
          <th>Joined Club Date</th>
          <th>Game Result</th>
          <th>Rake</th>
          <th>Buy-in</th>
          <th>Deposit</th>
          <th>Cashout</th>
          <th>Rating</th>
          <th>Agent</th>
          <th>Manager</th>
          <th>League Manager</th>
          <th>Kick</th>
        </tr>
      </thead>
      <tbody>
        {userIds.map((userId) => {
          const player = playerStats.get(userId);
          const isAgent = agentUserIds.includes(userId);
          const agentUserId = userAgents.get(userId);
          const agentDisplayId = agentUserId
            ? displayIds.get(agentUserId)
            : undefined;
          const managerPrivileges = managers.get(userId);
          const leagueManagerPrivileges = leagueManagers?.get(userId);
          return (
            <tr key={userId}>
              <Player
                id={userId}
                displayName={displayNames.get(userId) ?? userId}
                displayId={displayIds.get(userId) ?? ''}
                club={club}
                playerStats={player}
                isAgent={isAgent}
                managerPrivileges={managerPrivileges}
                leagueManagerPrivileges={leagueManagerPrivileges}
                agentDisplayId={agentDisplayId}
              />
            </tr>
          );
        })}
      </tbody>
    </HTMLTable>
  );
};

const PendingRequests: React.SFC<{
  clubId: string;
  userIds: string[];
  displayNames: Map<string, string>;
  displayIds: Map<string, string>;
}> = ({clubId, userIds, displayNames, displayIds}) => {
  if (userIds.length === 0) {
    return <div>No pending requests in club</div>;
  }
  return (
    <HTMLTable striped={true}>
      <thead>
        <tr>
          <th>ID</th>
          <th />
          <th />
        </tr>
      </thead>
      <tbody>
        {userIds.map((userId) => {
          return (
            <tr key={userId}>
              <Applicant
                id={userId}
                displayName={displayNames.get(userId) ?? userId}
                displayId={displayIds.get(userId) ?? ''}
                clubId={clubId}
              />
            </tr>
          );
        })}
      </tbody>
    </HTMLTable>
  );
};

const Applicant: React.SFC<{
  id: string;
  displayName: string;
  displayId: string;
  clubId: string;
}> = ({id, displayName, displayId, clubId}) => {
  const [ctx] = useCancelContext();
  const clubPrx = useIceProxy({
    type: 'static',
    propertyName: 'Webapp.Clubs',
    proxyClass: Gazebo.Clubs.ServicePrx,
  });

  const keyApprove = useIdempotenceKey([clubId, id]);
  const [acceptResponse, doApproveJoinRequest] = useRequest(
    () => clubPrx.approveJoinRequest(keyApprove, id, clubId),
    [clubPrx, keyApprove, id, clubId],
    ctx,
  );

  const keyDecline = useIdempotenceKey([clubId, id]);
  const [declineResponse, doRejectJoinRequest] = useRequest(
    () => clubPrx.declineJoinRequest(keyDecline, id, clubId),
    [clubPrx, keyDecline, id, clubId],
    ctx,
  );

  const disabled =
    acceptResponse?.type === 'started' || declineResponse?.type === 'started';

  return (
    <>
      <td className={disabled ? Classes.TEXT_MUTED : ''}>
        {displayName + '|' + displayId}
      </td>
      <td>
        <Button
          disabled={disabled}
          loading={acceptResponse?.type === 'started'}
          minimal={true}
          icon="tick"
          onClick={doApproveJoinRequest}
        />
      </td>
      <td>
        <Button
          disabled={disabled}
          loading={declineResponse?.type === 'started'}
          minimal={true}
          icon="cross"
          onClick={doRejectJoinRequest}
        />
      </td>
      <ResponseErrorToast response={declineResponse} />
      <ResponseErrorToast response={acceptResponse} />
    </>
  );
};

const Player: React.SFC<{
  id: string;
  displayName: string;
  displayId: string;
  club: Gazebo.Clubs.Club;
  playerStats: Gazebo.Clubs.ClubMemberStats | undefined;
  isAgent: boolean;
  managerPrivileges: Gazebo.Clubs.ManagerPrivileges | undefined;
  leagueManagerPrivileges: Gazebo.Clubs.LeagueManagerPrivileges | undefined;
  agentDisplayId: string | undefined;
}> = ({
  id,
  displayName,
  displayId,
  club,
  playerStats,
  isAgent,
  managerPrivileges,
  leagueManagerPrivileges,
  agentDisplayId,
}) => {
  const [ctx] = useCancelContext();
  const clubPrx = useIceProxy({
    type: 'static',
    propertyName: 'Webapp.Clubs',
    proxyClass: Gazebo.Clubs.ServicePrx,
  });
  const leaguesPrx = useIceProxy({
    type: 'static',
    propertyName: 'Webapp.Leagues',
    proxyClass: Gazebo.Leagues.ServicePrx,
  });
  const {clubId, isMyClub, leagueId} = club;
  const keyKick = useIdempotenceKey([clubId, id]);
  const [excludeResponse, doExcludeUserRequest] = useRequest(
    () => clubPrx.kickFromClub(keyKick, id, clubId),
    [clubPrx, keyKick, id, clubId],
    ctx,
  );

  const now = Date.now();
  const keyAgent = useIdempotenceKey([now]);
  const managerIdempotenceKey = useIdempotenceKey([
    now,
    clubId,
    managerPrivileges,
  ]);

  const leagueManagerIdempotenceKey = useIdempotenceKey([
    now,
    clubId,
    leagueManagerPrivileges,
  ]);

  const [onDisplayIdChanged, newAgentDisplayId] = useInputState(
    '',
    parseString,
    skipValidation,
  );

  const [bindAgentResponse, doBindAgent] = useRequest(
    () =>
      clubPrx.bindAgentToUser(keyAgent, newAgentDisplayId.value, id, clubId),
    [keyAgent, newAgentDisplayId, id, clubId, clubPrx],
    ctx,
  );

  const [unbindAgentResponse, doUnbindAgent] = useRequest(
    () => clubPrx.unbindAgentFromUser(keyAgent, id, clubId),
    [keyAgent, id, clubId, clubPrx],
    ctx,
  );

  const [assignAgentResponse, doAssignAgent] = useRequest(
    () => clubPrx.assignAgent(keyAgent, id, clubId),
    [clubPrx, keyAgent, id, clubId],
    ctx,
  );

  const [unassignAgentResponse, doUnassignAgent] = useRequest(
    () => clubPrx.unassignAgent(keyAgent, id, clubId),
    [clubPrx, keyAgent, id, clubId],
    ctx,
  );

  const [assignManagerResponse, doAssignManager] = useRequest(
    () => clubPrx.assignManagers(managerIdempotenceKey, [id], clubId),
    [clubPrx, managerIdempotenceKey, id, clubId],
    ctx,
  );

  const [unassignManagerResponse, doUnassignManager] = useRequest(
    () => clubPrx.unassignManagers(managerIdempotenceKey, [id], clubId),
    [clubPrx, managerIdempotenceKey, id, clubId],
    ctx,
  );

  const [assignLeagueManagerResponse, doAssignLeagueManager] = useRequest(
    () =>
      leagueId
        ? leaguesPrx.assignManagers(leagueManagerIdempotenceKey, [id], leagueId)
        : () => {},
    [leaguesPrx, leagueManagerIdempotenceKey, id, leagueId],
    ctx,
  );

  const [unassignLeagueManagerResponse, doUnassignLeagueManager] = useRequest(
    () =>
      leagueId
        ? leaguesPrx.unassignManagers(
            leagueManagerIdempotenceKey,
            [id],
            leagueId,
          )
        : () => {},
    [leaguesPrx, leagueManagerIdempotenceKey, id, leagueId],
    ctx,
  );

  const [isStopperOpened, setIsStopperOpened] = React.useState<boolean>(false);
  const handleStopperOpen = React.useCallback(() => {
    setIsStopperOpened(true);
  }, [setIsStopperOpened]);
  const handleStopperClose = React.useCallback(() => {
    setIsStopperOpened(false);
  }, [setIsStopperOpened]);

  const disabled = excludeResponse?.type === 'started';
  const auth = useAuthContext();
  const agentCheckboxDisabled =
    unassignAgentResponse?.type === 'started' ||
    assignAgentResponse?.type === 'started' ||
    (isMyClub && auth.idTokenPayload.sub === id);
  const managerCheckboxDisabled =
    unassignManagerResponse?.type === 'started' ||
    assignManagerResponse?.type === 'started';
  const leagueManagerCheckboxDisabled =
    !leagueId ||
    unassignLeagueManagerResponse?.type === 'started' ||
    assignLeagueManagerResponse?.type === 'started';

  const hasAgent = agentDisplayId != undefined;
  const isManager = managerPrivileges != undefined;
  const isLeagueManager = leagueManagerPrivileges != undefined;

  return (
    <>
      <td className={disabled ? Classes.TEXT_MUTED : ''}>{displayName}</td>
      <td>{displayId}</td>
      <td>{agentDisplayId}</td>
      <td>{playerStats?.handsPlayed ?? 'N/A'}</td>
      <td>
        {playerStats?.lastTableSessionFinishedAtMs
          ? moment(playerStats.lastTableSessionFinishedAtMs.toNumber()).format(
              'YYYY-MM-DD, HH:mm',
            )
          : 'N/A'}
      </td>
      <td>
        {playerStats?.joinedClubAtMs
          ? moment(playerStats.joinedClubAtMs.toNumber()).format('YYYY-MM-DD')
          : 'N/A'}
      </td>
      <td>
        {playerStats?.profitAmount != null
          ? centsToMainUnits(playerStats.profitAmount.toNumber())
          : 'N/A'}
      </td>
      <td>
        {playerStats?.totalRake != null
          ? centsToMainUnits(playerStats.totalRake.toNumber())
          : 'N/A'}
      </td>
      <td>
        {playerStats?.totalBuyIn != null
          ? centsToMainUnits(playerStats.totalBuyIn.toNumber())
          : 'N/A'}
      </td>
      <td>
        {playerStats?.chipsSentOut != null
          ? centsToMainUnits(playerStats.chipsSentOut.toNumber())
          : 'N/A'}
      </td>
      <td>
        {playerStats?.chipsRefunded != null
          ? centsToMainUnits(playerStats.chipsRefunded.toNumber())
          : 'N/A'}
      </td>
      <td>{playerStats?.rating ?? 'N/A'}</td>
      {/* <td>{playerStats?.complaintsCount ?? 'N/A'}</td> */}
      {/* <td>
        {playerStats?.isOnline != null ? (
          <Checkbox checked={playerStats.isOnline} disabled={true} />
        ) : (
          'N/A'
        )}
      </td> */}
      <td>
        {
          <Checkbox
            checked={isAgent}
            onChange={isAgent ? doUnassignAgent : doAssignAgent}
            disabled={agentCheckboxDisabled}
          />
        }
      </td>
      <td>
        {
          <Checkbox
            checked={isManager}
            onChange={isManager ? doUnassignManager : doAssignManager}
            disabled={managerCheckboxDisabled}
          />
        }
      </td>
      <td>
        {
          <Checkbox
            checked={isLeagueManager}
            onChange={
              isLeagueManager ? doUnassignLeagueManager : doAssignLeagueManager
            }
            disabled={leagueManagerCheckboxDisabled}
          />
        }
      </td>
      <td>
        <Button
          disabled={disabled}
          loading={excludeResponse?.type === 'started'}
          minimal={true}
          icon="cross"
          onClick={handleStopperOpen}
        />
        <Stopper
          title={'Kick player'}
          text={`Are you sure you want to kick ${displayName}?`}
          doRequest={doExcludeUserRequest}
          handleStopperClose={handleStopperClose}
          isOpened={isStopperOpened}
        />
      </td>
      <td>
        <InputGroup
          id="text-input"
          value={newAgentDisplayId.rawValue}
          onChange={onDisplayIdChanged}
          disabled={hasAgent}
        />
        <Button type="submit" onClick={hasAgent ? doUnbindAgent : doBindAgent}>
          {hasAgent ? 'Unbind' : 'Bind'}
        </Button>
      </td>
      <ResponseErrorToast response={excludeResponse} />
      <ResponseErrorToast response={assignAgentResponse} />
      <ResponseErrorToast response={unassignAgentResponse} />
      <ResponseErrorToast response={assignManagerResponse} />
      <ResponseErrorToast response={unassignManagerResponse} />
      <ResponseErrorToast response={assignLeagueManagerResponse} />
      <ResponseErrorToast response={unassignLeagueManagerResponse} />
      <ResponseErrorToast response={bindAgentResponse} />
      <ResponseErrorToast response={unbindAgentResponse} />
    </>
  );
};
