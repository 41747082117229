import {
  Alignment,
  Button,
  Classes,
  Colors,
  Drawer,
  Icon,
  Intent,
  Menu,
  MenuItem,
  Navbar,
  Position,
  Spinner,
  Tag,
} from '@blueprintjs/core';
import * as React from 'react';
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom';

import {Gazebo} from '@slices/Gazebo';

import {useAuthContext} from '../auth';
import {getResponseData, getResponseError} from '../ice-client-react';
import {iceProperties} from '../iceProperties';
import {ClubLobbyProvider} from '../store/clubLobby';
import {useClub, useClubs} from '../store/clubs';
import {useLeague} from '../store/leagues';
import {TournamentsLobbyProvider} from '../store/tournamentsLobby';
import {getClass, getClasses} from '../utils/css';
import {nullableToList} from '../utils/emptyList';

const classes = getClasses({
  root: {
    maxWidth: '100vw',
    maxHeight: '100vh',
    overflow: 'hidden',
  },
  navbar: {
    position: 'fixed',
    top: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
  clubName: {
    marginLeft: 15,
    marginTop: -3,
    fontSize: '130%',
  },
  page: {
    paddingTop: 0,
    maxWidth: '100vw',
    maxHeight: '100vh',
  },
  sidebar: {
    $nest: {
      [`& .${Classes.OVERLAY}, & .${Classes.DRAWER}`]: {
        width: 180,
      },
    },
  },
  content: {
    display: 'flex',
    maxWidth: '100vw',
    maxHeight: '100vh',
    overflow: 'auto',
    flexDirection: 'row',
    justifyContent: 'center',
    $nest: {
      '&>*': {
        maxWidth: 900,
      },
    },
    paddingTop: 55,
  },
  contentWidth: {
    width: 700,
  },
  clubIdRoot: {
    flexDirection: 'column',
  },
  masterClub: {
    textAlign: 'right',
    fontSize: '50%',
    color: Colors.GREEN5,
  },
  clubRole: {
    color: Colors.GREEN5,
  },
});

export const Layout: React.SFC = ({children}) => {
  const auth = useAuthContext();
  const logout = React.useCallback(() => {
    auth.logout();
  }, [auth]);
  const location = useLocation();
  const isSidebarEnabled = location.pathname !== '/';

  const [isSidebarOpened, setIsSidebarOpened] = React.useState<boolean>(false);

  const onSidebarClosed = React.useCallback(() => {
    setIsSidebarOpened(false);
  }, [setIsSidebarOpened]);

  const onSidebarOpen = React.useCallback(() => {
    setIsSidebarOpened(true);
  }, [setIsSidebarOpened]);

  const match = useRouteMatch<{clubOrDisplayId: string}>('/:clubOrDisplayId');

  return (
    <div className={classes.root}>
      <ClubLobbyProvider clubOrDisplayId={match?.params.clubOrDisplayId}>
        <TournamentsLobbyProvider
          clubOrDisplayId={match?.params.clubOrDisplayId}
        >
          <Navbar className={classes.navbar}>
            {isSidebarEnabled && (
              <Navbar.Group align={Alignment.LEFT}>
                <Button
                  className={Classes.MINIMAL}
                  icon="menu"
                  onClick={onSidebarOpen}
                />
              </Navbar.Group>
            )}
            <Header />
            <Navbar.Group align={Alignment.RIGHT}>
              <Button
                className={Classes.MINIMAL}
                icon="log-out"
                text="Logout"
                onClick={logout}
              />
            </Navbar.Group>
          </Navbar>
          <section className={classes.page}>
            <div className={classes.sidebar}>
              <Sidebar isOpen={isSidebarOpened} onClose={onSidebarClosed} />
            </div>
            <div className={classes.content}>
              <div className={classes.contentWidth}>{children}</div>
            </div>
          </section>
        </TournamentsLobbyProvider>
      </ClubLobbyProvider>
    </div>
  );
};

const Header = () => {
  const location = useLocation();
  const isEmpty = location.pathname === '/';
  const match = useRouteMatch<{clubOrDisplayId: string}>('/:clubOrDisplayId');
  const response = useClub(match?.params.clubOrDisplayId);

  const club = getResponseData(response);

  const leagueResponse = useLeague(club?.leagueId);
  const league = getResponseData(leagueResponse)?.[0];
  const auth = useAuthContext();

  if (isEmpty || club == null) {
    return (
      <Navbar.Group align={Alignment.CENTER}>
        <Navbar.Heading>Club Admin</Navbar.Heading>
      </Navbar.Group>
    );
  }
  return (
    <Navbar.Group align={Alignment.CENTER}>
      {auth.idTokenPayload.nickname}
      <Navbar.Divider />
      {club.isMyClub && (
        <Navbar.Group align={Alignment.CENTER} className={classes.clubRole}>
          {club.clubId === league?.ownerClubId ? 'LH' : 'CH'}
          <Navbar.Divider />
        </Navbar.Group>
      )}
      <Navbar.Heading className={classes.clubName}>{club.name}</Navbar.Heading>
      <Navbar.Divider />
      <div className={classes.clubIdRoot}>
        <div>{`#${club.displayId}`}</div>
        <div className={classes.masterClub}>
          {league != null && club.clubId == league.ownerClubId
            ? 'Master club'
            : null}
        </div>
      </div>
      <Navbar.Divider />
      {club.userIds.length}
      <Icon iconSize={12} icon="person" style={{marginLeft: 5}} />
      <Navbar.Divider />
      {club.userWaitingApprovalIds.length}
      <Icon icon="new-person" style={{marginTop: -4, marginLeft: 5}} />
    </Navbar.Group>
  );
};

const Sidebar: React.FunctionComponent<{isOpen: boolean; onClose(): void}> = ({
  isOpen,
  onClose,
}) => {
  const backendVersion = iceProperties.getProperty('Webapp.Version');
  return (
    <Drawer
      portalClassName={classes.sidebar}
      icon="info-sign"
      onClose={onClose}
      title="Menu"
      isOpen={isOpen}
      position={Position.LEFT}
      hasBackdrop={false}
      canOutsideClickClose={false}
      autoFocus={false}
      enforceFocus={false}
    >
      <div className={Classes.DRAWER_BODY}>
        <Menu>
          <ClubsMenu />
          <PlayersMenuItem />
          <BalanceMenuItem />
          <TablesMenuItem />
          <TournamentsMenuItem />
          <LeagueMenuItem />
          <StatsMenuItem />
        </Menu>
      </div>
      <div className={Classes.DRAWER_FOOTER}>
        Backend version: {backendVersion}
      </div>
    </Drawer>
  );
};

const clubsMenuClass = getClass({
  $nest: {
    '&>*': {
      maxHeight: '50vh',
      overflow: 'auto',
    },
  },
});

const ClubsMenu: React.FC = () => {
  const clubsResponse = useClubs();

  const clubs = nullableToList(getResponseData(clubsResponse));
  const error = getResponseError(clubsResponse);

  if (error != null) {
    return <MenuItem text="Loading error" />;
  }

  if (clubs.length === 1) {
    return null;
  }

  return (
    <MenuItem popoverProps={{popoverClassName: clubsMenuClass}} text="Clubs">
      {getResponseData(clubsResponse)?.map((club) => (
        <ClubMenuItem key={club.clubId} club={club} />
      ))}
    </MenuItem>
  );
};

const ClubMenuItem: React.FC<{club: Gazebo.Clubs.Club}> = ({club}) => {
  const match = useRouteMatch<{clubOrDisplayId: string}>('/:clubOrDisplayId');
  const isRouteMatched =
    match?.params.clubOrDisplayId === club.clubId ||
    match?.params.clubOrDisplayId === club.displayId;

  const history = useHistory();

  const gotoClub = React.useCallback(() => {
    if (!isRouteMatched) {
      history.push(`/${club.clubId}/players`);
    }
  }, [history, club, isRouteMatched]);

  return (
    <MenuItem active={isRouteMatched} text={club.name} onClick={gotoClub} />
  );
};

const useGoto = (routePath: string): [boolean, () => void] => {
  const match = useRouteMatch<{clubOrDisplayId: string}>('/:clubOrDisplayId');

  const isRouteMatched =
    useRouteMatch<{clubOrDisplayId: string}>(
      `/:clubOrDisplayId/${routePath}`,
    ) != null;

  const clubId = useClub(match?.params.clubOrDisplayId)?.data?.clubId;

  const history = useHistory();

  const goto = React.useCallback(() => {
    if (!isRouteMatched) {
      history.push(`/${clubId}/${routePath}`);
    }
  }, [history, clubId, isRouteMatched, routePath]);

  return [isRouteMatched, goto];
};

const PlayersMenuItem: React.SFC = () => {
  const [isRouteMatched, goto] = useGoto('players');
  return (
    <MenuItem
      active={isRouteMatched}
      onClick={goto}
      text="Players"
      icon="user"
    />
  );
};

const BalanceMenuItem: React.SFC = () => {
  const [isRouteMatched, goto] = useGoto('balance');
  return (
    <MenuItem
      active={isRouteMatched}
      onClick={goto}
      text="Balance"
      icon="dollar"
    />
  );
};

const TablesMenuItem: React.SFC = () => {
  const [isRouteMatched, goto] = useGoto('tables');
  const match = useRouteMatch<{clubOrDisplayId: string}>('/:clubOrDisplayId');
  const clubResponse = useClub(match?.params.clubOrDisplayId);

  const tablesCount =
    clubResponse?.type === 'started' ? (
      <Spinner size={10} />
    ) : (
      <Tag round={true} intent={isRouteMatched ? Intent.NONE : Intent.PRIMARY}>
        {getResponseData(clubResponse)?.tableCount}
      </Tag>
    );

  const text = <>Tables {tablesCount}</>;
  return (
    <MenuItem active={isRouteMatched} onClick={goto} text={text} icon="grid" />
  );
};

const TournamentsMenuItem: React.FunctionComponent = () => {
  const [isRouteMatched, goto] = useGoto('tournaments');
  return (
    <MenuItem
      active={isRouteMatched}
      onClick={goto}
      text="Tournaments"
      icon="grid"
    />
  );
};

const StatsMenuItem: React.FunctionComponent = () => {
  const [isRouteMatched, goto] = useGoto('stats');
  return (
    <MenuItem
      active={isRouteMatched}
      onClick={goto}
      text="Stats"
      icon="history"
    />
  );
};

const LeagueMenuItem: React.SFC = () => {
  const [isRouteMatched, goto] = useGoto('league');
  return (
    <MenuItem
      active={isRouteMatched}
      onClick={goto}
      text="League"
      icon="people"
    />
  );
};
